// Lib
import { createApi } from "@reduxjs/toolkit/query/react";
// Types
import {
  RewardsSettingsResponseDto,
  SetCoreSettingsRequestDto,
} from "types/coreSettings";
// Utils
import { coreQuery } from "utils/baseQuery";

import { endpoints } from "consts";
import {
  coreSettingsPayloadMapper,
  rewardsSettingsMapper,
  rewardsSettingsPayloadMapper,
} from "utils/mappers";

export const coreSettingsAPI = createApi({
  reducerPath: "coreSettingsAPI",
  baseQuery: coreQuery(),
  tagTypes: ["rewards"],

  endpoints: build => ({
    updateCoreSettings: build.mutation<void, SetCoreSettingsRequestDto>({
      query: data => ({
        url: endpoints.coreSettings,
        method: "PUT",
        data: coreSettingsPayloadMapper(data),
      }),
    }),
    getRewardSettings: build.query<RewardsSettingsResponseDto, void>({
      query: () => ({
        url: endpoints.rewards,
        method: "GET",
      }),
      providesTags: ["rewards"],
      transformResponse: (response: RewardsSettingsResponseDto) =>
        rewardsSettingsMapper(response),
    }),
    updateRewardSettings: build.mutation<
      RewardsSettingsResponseDto,
      RewardsSettingsResponseDto
    >({
      query: data => ({
        url: endpoints.rewards,
        method: "PUT",
        data: rewardsSettingsPayloadMapper(data),
      }),
      invalidatesTags: ["rewards"],
    }),
  }),
});

export const {
  useUpdateCoreSettingsMutation,
  useLazyGetRewardSettingsQuery,
  useUpdateRewardSettingsMutation,
} = coreSettingsAPI;
