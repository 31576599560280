// Lib
import { FC, useEffect, useState } from "react";
// Api
import {
  useLazyGetRewardSettingsQuery,
  useUpdateRewardSettingsMutation,
} from "rtkQuery/query/coreSettingsAPI";
// Hooks
import { useNotification } from "hooks";
// Helpers
import { getConfirmModalTitle } from "../../helpers";
import { createPayload } from "./helpers";
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
import { PersonIcon } from "icons";
// Components
import { ConfirmDialog } from "components/Modals";
import SettingsItem from "../SettingsItem";
import { Input } from "../Input";
import { Switch } from "../Switch";
import { Select } from "../Select";
// Styled
import { ContentBox, SubContentBox } from "styled/Box";
import { Typography } from "styled/Typography";

import {
  ConfirmModalType,
  initialLoadingState,
  initialState,
  LoadingStateValues,
  notificationMessage,
  RewardsField,
  StateValues,
} from "./config";

export const Rewards: FC = () => {
  const { openNotification } = useNotification();

  const [fetchRewardsSettings] = useLazyGetRewardSettingsQuery();
  const [updateRewardsSettings] = useUpdateRewardSettingsMutation();

  const [settings, setSettings] = useState<StateValues>(initialState);

  const [isLoading, setIsLoading] =
    useState<LoadingStateValues>(initialLoadingState);

  const [confirmModal, setConfirmModal] = useState<ConfirmModalType | false>(
    false,
  );

  const getRewardsSettings = async () => {
    try {
      setIsLoading(prev => ({
        ...prev,
        ...Object.fromEntries(Object.values(prev).map(field => [field, true])),
      }));

      const {
        weekStartDay,
        isRewardsEnabled,
        bonusCoins,
        diaryMetricSettings,
        exerciseMetricSettings,
        metricsMinRequired,
        stepMetricSettings,
      } = await fetchRewardsSettings().unwrap();

      setSettings(prev => ({
        ...prev,
        isRewardsEnabled,
        weekStartDay,
        bonusCoins,
        metricsMinRequired,
        [RewardsField.StepMetricRequiredStepsDays]:
          stepMetricSettings.requiredStepsDays,
        [RewardsField.StepMetricRequiredStepsPerDay]:
          stepMetricSettings.requiredStepsPerDay,
        [RewardsField.ExerciseMetricMinutesCount]:
          exerciseMetricSettings.minutesCount,
        [RewardsField.DiaryMetricDaysCount]: diaryMetricSettings.daysCount,
      }));
    } catch (error) {
      errorHandler({ error, openNotification });
    } finally {
      setIsLoading(prev => ({
        ...prev,
        ...Object.fromEntries(Object.values(prev).map(field => [field, false])),
      }));
    }
  };

  useEffect(() => {
    getRewardsSettings();
  }, []);

  const handleUpdateRewarsSettings = async (
    key: RewardsField,
    value: StateValues[RewardsField],
  ) => {
    try {
      setIsLoading(prev => ({ ...prev, [key]: true }));

      const payload = createPayload(settings, key, value);

      await updateRewardsSettings(payload).unwrap();

      setSettings(prev => ({ ...prev, [key]: value }));

      openNotification({ message: notificationMessage[key] });
    } catch (error) {
      errorHandler({ error, openNotification });
    } finally {
      setIsLoading(prev => ({ ...prev, [key]: false }));
    }
  };

  const handleUpdateBooleanSettings = async (
    key: RewardsField,
    value: boolean,
  ) => {
    try {
      setIsLoading(prev => ({ ...prev, [key]: true }));

      setSettings(prev => ({ ...prev, [key]: value }));

      const payload = createPayload(settings, key, value);

      await updateRewardsSettings(payload).unwrap();

      openNotification({ message: notificationMessage[key] });
    } catch (error) {
      setSettings(prev => ({ ...prev, [key]: !value }));

      errorHandler({ error, openNotification });
    } finally {
      setIsLoading(prev => ({ ...prev, [key]: false }));

      if (confirmModal) {
        setConfirmModal(false);
      }
    }
  };

  const handleUpdate = (
    key: RewardsField,
    value: StateValues[RewardsField],
  ) => {
    switch (key) {
      case RewardsField.IsRewardsEnabled:
        setSettings(prev => ({ ...prev, [key]: value as boolean }));
        setConfirmModal({ key, value });
        return;

      default:
        handleUpdateRewarsSettings(key, value);
        return;
    }
  };

  const onConfirm = () => {
    if (!confirmModal) return;

    switch (confirmModal.key) {
      case RewardsField.IsRewardsEnabled:
        handleUpdateBooleanSettings(
          confirmModal.key,
          confirmModal.value as boolean,
        );
        return;

      default:
        break;
    }
  };

  const onCancel = () => {
    if (!confirmModal) return;
    setSettings(prev => ({ ...prev, [confirmModal.key]: !confirmModal.value }));
    setConfirmModal(false);
  };

  return (
    <>
      <ContentBox $column $gap={12}>
        <Typography.H2>Rewards</Typography.H2>

        <div>
          <SettingsItem title="Enable rewards">
            <Switch
              isLoading={isLoading[RewardsField.IsRewardsEnabled]}
              canUpdate={true}
              data={settings[RewardsField.IsRewardsEnabled]}
              onChange={value =>
                handleUpdate(RewardsField.IsRewardsEnabled, value)
              }
            />
          </SettingsItem>

          <SettingsItem
            title="Reward week start day"
            description="Set day of the week Sunday by default"
          >
            <Select
              isLoading={isLoading[RewardsField.WeekStartDay]}
              canUpdate={true}
              data={settings[RewardsField.WeekStartDay]}
              onChange={value => handleUpdate(RewardsField.WeekStartDay, value)}
            />
          </SettingsItem>

          <SettingsItem
            title="Coins reward"
            description="Set a number of free coins that can be used in each order"
          >
            <Input
              suffix="FM Coins"
              step={1}
              isLoading={isLoading[RewardsField.BonusCoins]}
              canUpdate={true}
              data={settings[RewardsField.BonusCoins]}
              onSubmit={value => handleUpdate(RewardsField.BonusCoins, value)}
            />
          </SettingsItem>

          <SettingsItem
            title="Required metrics count"
            description="Set a number of the completed metrics that are required to earn a weekly coin (how many metrics are required)"
          >
            <Input
              suffix="metric(s)"
              step={1}
              isLoading={isLoading[RewardsField.MetricsMinRequired]}
              canUpdate={true}
              data={settings[RewardsField.MetricsMinRequired]}
              onSubmit={value =>
                handleUpdate(RewardsField.MetricsMinRequired, value)
              }
            />
          </SettingsItem>
        </div>

        <SubContentBox
          $column
          $gap={12}
          $padding="12px 12px 0px"
          $margin="0 -12px 0 -12px"
          $width="auto"
        >
          <Typography.H3>&quot;Steps&quot; Metric</Typography.H3>

          <div>
            <SettingsItem
              title="Steps count"
              description="Set a number of steps that are required to be completed for the determined number of days in a week"
            >
              <Input
                suffix="steps"
                step={1}
                isLoading={
                  isLoading[RewardsField.StepMetricRequiredStepsPerDay]
                }
                canUpdate={true}
                data={settings[RewardsField.StepMetricRequiredStepsPerDay]}
                onSubmit={value =>
                  handleUpdate(
                    RewardsField.StepMetricRequiredStepsPerDay,
                    value,
                  )
                }
              />
            </SettingsItem>

            <SettingsItem
              title="Days count"
              description="Set a number of days in a week during which customers should complete the determined number of steps"
            >
              <Input
                suffix="days"
                step={1}
                isLoading={isLoading[RewardsField.StepMetricRequiredStepsDays]}
                canUpdate={true}
                data={settings[RewardsField.StepMetricRequiredStepsDays]}
                onSubmit={value =>
                  handleUpdate(RewardsField.StepMetricRequiredStepsDays, value)
                }
              />
            </SettingsItem>
          </div>
        </SubContentBox>

        <SubContentBox
          $column
          $gap={12}
          $padding="12px 12px 0px"
          $margin="0 -12px 0 -12px"
          $width="auto"
        >
          <Typography.H3>&quot;Exercise Minutes&quot; Metric</Typography.H3>

          <div>
            <SettingsItem
              title="Minutes count"
              description="Set a number of minutes that are required to be accumulated over the determined number of days in a week"
            >
              <Input
                suffix="minutes"
                step={1}
                isLoading={isLoading[RewardsField.ExerciseMetricMinutesCount]}
                canUpdate={true}
                data={settings[RewardsField.ExerciseMetricMinutesCount]}
                onSubmit={value =>
                  handleUpdate(RewardsField.ExerciseMetricMinutesCount, value)
                }
              />
            </SettingsItem>
          </div>
        </SubContentBox>

        <SubContentBox
          $column
          $gap={12}
          $padding="12px 12px 0px"
          $margin="0 -12px 0 -12px"
          $width="auto"
        >
          <Typography.H3>&quot;Diary Usage&quot; Metric</Typography.H3>

          <div>
            <SettingsItem
              title="Days count"
              description="Set a number of consecutive days customers should log entries under the calorie target."
            >
              <Input
                suffix="days"
                step={1}
                isLoading={isLoading[RewardsField.DiaryMetricDaysCount]}
                canUpdate={true}
                data={settings[RewardsField.DiaryMetricDaysCount]}
                onSubmit={value =>
                  handleUpdate(RewardsField.DiaryMetricDaysCount, value)
                }
              />
            </SettingsItem>
          </div>
        </SubContentBox>
      </ContentBox>

      <ConfirmDialog
        isLoading={confirmModal && isLoading[confirmModal.key]}
        open={!!confirmModal}
        Icon={PersonIcon}
        message={
          confirmModal &&
          getConfirmModalTitle({
            key: confirmModal.key,
            value: !!confirmModal.value,
          })
        }
        description="Are you sure to continue this action?"
        onCancel={onCancel}
        firstCTAButton={{
          title: "Accept",
          status: "danger",
          disabled: false,
          loading: confirmModal && isLoading[confirmModal.key],
          onClick: onConfirm,
        }}
      />
    </>
  );
};
