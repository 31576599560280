// Lib
import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// Api
import {
  useGetReviewsQuery,
  useBulkUpdateReviewMutation,
} from "rtkQuery/query/reviewsAPI";
// Hooks
import { useNotification, useTable, useViewport } from "hooks";
// Types
import { TablePaginationConfig, TableProps } from "antd";
import { FilterValue, SorterResult } from "antd/es/table/interface";
import { ReviewResponseDto, ReviewStatus } from "types/reviews";
import { AdditionalFilter, ETable } from "types/tableFilters";
// Constants
import { ADMIN_ROUTES, rtkQueryParams } from "consts";
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
import { DescriptionBlackIcon, RightBurgerMenuIcon, StarIcon } from "icons";
// Components
import { DropDown, Pagination, Table } from "components";
import { ConfirmDialog } from "components/Modals";
// Styled
import { PageWrapper, FlexContainer } from "styled/Box";
import { Typography } from "styled/Typography";
import { Button } from "styled/Buttons";

import { columns, filtersList } from "./config";

export const Reviews: FC = () => {
  const { isDesktop } = useViewport();

  const navigate = useNavigate();

  const { openNotification } = useNotification();

  const [confirmModal, setConfirmModal] = useState({
    open: false,
    status: ReviewStatus.REVIEW,
  });
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const {
    sortingOrder,
    sortBy,
    page,
    limit,
    selectedFilters,
    debouncedFiltersQuery,
    setPage,
    setLimit,
    handleSort,
    handleSetTableFilterValues,
  } = useTable({ name: ETable.Reviews });

  const { data, isFetching, error } = useGetReviewsQuery(
    {
      query: {
        ...(debouncedFiltersQuery && debouncedFiltersQuery),
        page,
        limit,
        sortBy,
        sortingOrder,
      },
    },
    rtkQueryParams,
  );

  const [update, { isLoading: isBulkUpdateLoading }] =
    useBulkUpdateReviewMutation();

  useEffect(() => {
    if (error) {
      errorHandler({ error, openNotification });
    }
  }, [error]);

  const handleTableChange = useCallback(
    (
      _pagination: TablePaginationConfig,
      _filters: Record<string, FilterValue>,
      sorter: SorterResult,
    ) => {
      handleSort(sorter?.field as string, sorter?.order);
    },
    [],
  );

  const onRow = useCallback((record: ReviewResponseDto) => {
    return {
      onClick: () => navigate(`${ADMIN_ROUTES.REVIEWS.path}/${record.id}`),
    };
  }, []);

  const rowSelection: TableProps<ReviewResponseDto>["rowSelection"] = {
    selectedRowKeys: selectedRows,
    getCheckboxProps: (record: ReviewResponseDto) => ({
      disabled: record.status === ReviewStatus.APPROVED,
    }),

    onSelect: (record: ReviewResponseDto, selected: boolean) => {
      if (selected) {
        setSelectedRows(prev => [...prev, record.id]);
      } else {
        setSelectedRows(prev => prev.filter(id => id !== record.id));
      }
    },

    onSelectAll: (
      selected: boolean,
      _selectedRows: ReviewResponseDto[],
      changeRows: ReviewResponseDto[],
    ) => {
      const changedIds = changeRows.map(row => row.id);
      if (selected) {
        setSelectedRows(prev => prev.concat(changedIds));
      } else {
        setSelectedRows(prev =>
          prev.filter(prevId => !changedIds.find(id => id === prevId)),
        );
      }
    },
  };

  const handleBulkApprove = () => {
    if (!selectedRows?.length) {
      return;
    }

    setConfirmModal({ open: true, status: ReviewStatus.APPROVED });
  };

  const handleBulkReject = () => {
    if (!selectedRows?.length) {
      return;
    }

    setConfirmModal({ open: true, status: ReviewStatus.REJECTED });
  };

  const onBulkUpdateCancel = () => {
    if (isBulkUpdateLoading) {
      return;
    }

    setConfirmModal({ open: false, status: ReviewStatus.REVIEW });
  };

  const onBulkUpdateSubmit = async () => {
    if (!selectedRows?.length) {
      return;
    }

    console.log(confirmModal.status);

    try {
      await update({ ids: selectedRows, status: confirmModal.status }).unwrap();

      setSelectedRows([]);
      setConfirmModal({ open: false, status: ReviewStatus.REVIEW });
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const dropDownMenuItems = [
    {
      key: "3",
      label: "Reject",
      onClick: handleBulkReject,
    },
    {
      key: "4",
      label: "Approve",
      onClick: handleBulkApprove,
    },
  ];

  const tableApproveRejectButtons = selectedRows?.length ? (
    <FlexContainer $gap={10}>
      {isDesktop ? (
        <>
          <Button.Base type="primary" onClick={handleBulkApprove}>
            Approve
          </Button.Base>

          <Button.Base status="danger" onClick={handleBulkReject}>
            Reject
          </Button.Base>
        </>
      ) : (
        <DropDown items={dropDownMenuItems} trigger={["click"]}>
          <Button.SquaredIcon icon={<RightBurgerMenuIcon />} />
        </DropDown>
      )}
    </FlexContainer>
  ) : null;

  return (
    <>
      <PageWrapper $fullwidth $column>
        <FlexContainer
          $fullwidth
          $column
          $grow={1}
          $padding={isDesktop && "0 0 32px"}
        >
          <FlexContainer
            $padding="0 0 24px"
            $align="center"
            $justify="space-between"
          >
            <Typography.H1>Rates and Reviews</Typography.H1>
          </FlexContainer>

          <Table
            isLoading={isFetching}
            dataSource={data?.items || []}
            columns={columns}
            withPagination={!isDesktop}
            empty={{
              icon: DescriptionBlackIcon,
              title: "No reviews to show.",
              description: "Try changing filters",
            }}
            header={{
              totalCount: data?.totalCount,
              filter: {
                include: [AdditionalFilter.Customer, AdditionalFilter.Rating],
                filters: filtersList,
                selected: selectedFilters,
                setValue: handleSetTableFilterValues,
              },
              suffix: tableApproveRejectButtons,
              suffixPosition: "start",
            }}
            onRow={onRow}
            rowSelection={{ type: "checkbox", ...rowSelection }}
            onChange={handleTableChange}
          />
        </FlexContainer>

        <Pagination
          onPage
          padding="12px 16px"
          limit={limit}
          page={page}
          setLimit={setLimit}
          setPage={setPage}
          showSizeChanger={!isDesktop}
          totalItems={data?.totalCount}
        />
      </PageWrapper>

      <ConfirmDialog
        isLoading={isBulkUpdateLoading}
        open={confirmModal.open}
        Icon={StarIcon}
        message={`${selectedRows?.length} reviews will be ${
          confirmModal.status === ReviewStatus.APPROVED
            ? "approved"
            : "rejected"
        }`}
        description="Are you sure to continue this action?"
        onCancel={onBulkUpdateCancel}
        firstCTAButton={{
          title:
            confirmModal.status === ReviewStatus.APPROVED
              ? "Approve"
              : "Reject",
          type:
            confirmModal.status === ReviewStatus.APPROVED
              ? "primary"
              : undefined,
          status:
            confirmModal.status === ReviewStatus.REJECTED
              ? "danger"
              : undefined,
          loading: isBulkUpdateLoading,
          onClick: onBulkUpdateSubmit,
        }}
      />
    </>
  );
};
