import {
  RewardsSettingsResponseDto,
  WeekStartDayValue,
} from "types/coreSettings";
import { RewardsField, StateValues } from "./config";

export const createPayload = (
  state: StateValues,
  key: RewardsField,
  value: StateValues[RewardsField],
): RewardsSettingsResponseDto => {
  const payload: RewardsSettingsResponseDto = {
    weekStartDay: state[RewardsField.WeekStartDay],
    isRewardsEnabled: state[RewardsField.IsRewardsEnabled],
    bonusCoins: state[RewardsField.BonusCoins],
    metricsMinRequired: state[RewardsField.MetricsMinRequired],
    stepMetricSettings: {
      requiredStepsDays: state[RewardsField.StepMetricRequiredStepsDays],
      requiredStepsPerDay: state[RewardsField.StepMetricRequiredStepsPerDay],
    },
    exerciseMetricSettings: {
      minutesCount: state[RewardsField.ExerciseMetricMinutesCount],
    },
    diaryMetricSettings: {
      daysCount: state[RewardsField.DiaryMetricDaysCount],
    },
  };

  if (typeof value === "number") {
    switch (key) {
      case RewardsField.BonusCoins:
        payload.bonusCoins = value;
        break;
      case RewardsField.MetricsMinRequired:
        payload.metricsMinRequired = value;
        break;
      case RewardsField.StepMetricRequiredStepsDays:
        payload.stepMetricSettings = {
          ...payload.stepMetricSettings,
          requiredStepsDays: value,
        };
        break;
      case RewardsField.StepMetricRequiredStepsPerDay:
        payload.stepMetricSettings = {
          ...payload.stepMetricSettings,
          requiredStepsPerDay: value,
        };
        break;
      case RewardsField.ExerciseMetricMinutesCount:
        payload.exerciseMetricSettings = {
          ...payload.exerciseMetricSettings,
          minutesCount: value,
        };
        break;
      case RewardsField.DiaryMetricDaysCount:
        payload.diaryMetricSettings = {
          ...payload.exerciseMetricSettings,
          daysCount: value,
        };
        break;

      default:
        break;
    }
  }

  switch (key) {
    case RewardsField.WeekStartDay:
      payload.weekStartDay = value as WeekStartDayValue;
      break;
    case RewardsField.IsRewardsEnabled:
      payload.isRewardsEnabled = value as boolean;
      break;

    default:
      break;
  }

  return payload;
};
