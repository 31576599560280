// Lib
import { FC } from "react";
import { useNavigate } from "react-router-dom";
// Types
import { OrderExternalServiceLabel } from "types/orders";
import { SummaryProps } from "./types";
// Theme
// Constants
import { ADMIN_ROUTES } from "consts";
// Helpers
import { fixedDigitsValue } from "helpers/dataHelpers";
// Icons
import { LocationsIcon } from "icons";
// Components
import { FilledButtonLink, SummaryField } from "components";
// Styled
import { ContentBox, SummaryItemsGrid } from "styled/Box";
import { Typography } from "styled/Typography";

export const Summary: FC<SummaryProps> = ({ isLoading, data }) => {
  const {
    firstName,
    sid,
    totalFiatCentsPrice,
    locationId,
    locationName,
    service,
    orderId,
    hashTag,
  } = data || {};

  const navigate = useNavigate();

  const handleOpenLocation = () => {
    navigate(`${ADMIN_ROUTES.DELIVERY_AREAS.path}/${locationId}`);
  };

  return (
    <ContentBox $column $gap={20}>
      <Typography.H2>Summary</Typography.H2>

      <SummaryItemsGrid>
        <SummaryField isLoading={isLoading} label="Order ID" value={orderId} />

        <SummaryField isLoading={isLoading} label="#Number" value={hashTag} />

        <SummaryField isLoading={isLoading} label="Order number" value={sid} />

        <SummaryField
          isLoading={isLoading}
          label="Customer"
          value={firstName}
        />

        <SummaryField
          isLoading={isLoading}
          label="Delivery partner"
          value={OrderExternalServiceLabel[service]}
        />

        <SummaryField
          isLoading={isLoading}
          label="KWD"
          value={fixedDigitsValue(totalFiatCentsPrice, 3)}
        />

        <SummaryField
          isLoading={isLoading}
          label="Location"
          value={
            <FilledButtonLink
              icon={<LocationsIcon />}
              title={locationName}
              onClick={handleOpenLocation}
            />
          }
        />
      </SummaryItemsGrid>
    </ContentBox>
  );
};
