// Lib
import { FC, useEffect, useState } from "react";
import { Spin } from "antd";
// Api
// Hooks
import { useViewport } from "hooks";
// Actions
// Selectors
// Types
import { FilterStateItem } from "types/tableFilters";
import { TableHeaderProps } from "../../types";
// Theme
import { theme } from "theme";
// Constants
// Helpers
import { createFiltersDropdown, updateFiltersData } from "../../helpers";
// Utils
// Icons
import { SearchBlackIcon, TuneBlackFilterIcon } from "icons";
// Layouts
// Components
import { Badge, DropDown } from "components";
import { SearchInput } from "components/Form";
import { FiltersBar } from "../FiltersBar";
// Styled
import { Typography } from "styled/Typography";
import { FlexContainer } from "styled/Box";
import { Button } from "styled/Buttons";
import {
  HeaderTabs,
  MobileFilterButtonWrapper,
  RedDot,
  Wrapper,
  MobileSearchWrapper,
} from "./styled";

export const TableHeader: FC<TableHeaderProps> = ({
  isLoading,
  totalCount,
  search,
  filter,
  tabs,
  suffix,
  leftTitle,
  suffixPosition = "end",
}) => {
  const { isMobile } = useViewport();

  const [isMobileSearch, setIsMobileSearch] = useState(false);

  const [collapsed, setCollapsed] = useState(true);

  const filtersData = filter?.include
    ? updateFiltersData(filter.filters, filter?.include)
    : filter?.filters;

  const dropdownFilters = !!filter && createFiltersDropdown(filtersData);

  const isAnyFilterExist = !!filter?.selected?.length;

  useEffect(() => {
    setCollapsed(!isAnyFilterExist);
  }, [isAnyFilterExist]);

  const setFilter = filterData => {
    if (
      !filter?.selected?.find(
        (f: FilterStateItem) => f?.name === filterData.key,
      )
    ) {
      const data: FilterStateItem[] = [
        ...filter.selected,
        {
          name: filterData.key,
          value: "",
          field: filtersData.find(filter => filter.key === filterData.key)
            .field,
        },
      ];

      filter?.setValue(data);
    }
  };

  const handleCollapseFilters = () => {
    setCollapsed(prev => !prev);
  };

  return (
    <Wrapper $column $isSelectedFilter={!!filter?.selected}>
      <FlexContainer $align="center" $justify="space-between">
        {leftTitle && <Typography.H2>{leftTitle}</Typography.H2>}

        {!!tabs && (
          <HeaderTabs items={tabs?.items} onTabClick={e => tabs.setTab(e)} />
        )}

        {totalCount ? (
          <Typography.Description>
            Total records
            <Badge
              title={`${totalCount}`}
              bgColor="white"
              textColor={theme.color.black}
              border={`1px solid ${theme.color.borderLight}`}
              style={{ marginLeft: 8 }}
            />
          </Typography.Description>
        ) : (
          <div />
        )}

        <FlexContainer $gap={10}>
          {!!search && !isMobile && (
            <SearchInput
              value={search.value}
              setValue={search.setValue}
              placeholder={search.placeholder}
            />
          )}

          {!!search && isMobile && (
            <Button.SquaredIcon
              icon={<SearchBlackIcon />}
              onClick={() => setIsMobileSearch(prev => !prev)}
            />
          )}

          {!!suffix && suffixPosition === "start" && suffix}

          {!!filter?.filters.length && isAnyFilterExist ? (
            <>
              {isMobile ? (
                <MobileFilterButtonWrapper>
                  <Button.SquaredIcon
                    icon={<TuneBlackFilterIcon />}
                    onClick={handleCollapseFilters}
                  />

                  <RedDot onClick={handleCollapseFilters} />
                </MobileFilterButtonWrapper>
              ) : (
                <Button.SubHeading
                  icon={<TuneBlackFilterIcon />}
                  onClick={handleCollapseFilters}
                >
                  Filter
                  <RedDot />
                </Button.SubHeading>
              )}
            </>
          ) : (
            !!filter?.filters.length && (
              <>
                {isMobile ? (
                  <DropDown
                    items={dropdownFilters}
                    trigger={["click"]}
                    onClick={setFilter}
                  >
                    <MobileFilterButtonWrapper>
                      <Button.SquaredIcon icon={<TuneBlackFilterIcon />} />
                      {isAnyFilterExist && <RedDot />}
                    </MobileFilterButtonWrapper>
                  </DropDown>
                ) : (
                  <DropDown
                    items={dropdownFilters}
                    trigger={["click"]}
                    onClick={setFilter}
                  >
                    <Button.SubHeading icon={<TuneBlackFilterIcon />}>
                      Filter
                      {isAnyFilterExist && <RedDot />}
                    </Button.SubHeading>
                  </DropDown>
                )}
              </>
            )
          )}
          {!!suffix && suffixPosition === "end" && suffix}
        </FlexContainer>
      </FlexContainer>

      {!!search && isMobile && (
        <MobileSearchWrapper
          style={{
            height: isMobileSearch ? (collapsed ? "60px" : "50px") : "0px",
          }}
        >
          <SearchInput
            isMobile
            value={search.value}
            setValue={search.setValue}
            placeholder={search.placeholder}
          />
        </MobileSearchWrapper>
      )}

      {!!filter && (
        <Spin indicator={<></>} spinning={isLoading}>
          <FiltersBar
            collapsed={collapsed}
            value={filter?.value}
            selected={filter.selected}
            filters={filtersData}
            setValue={filter.setValue}
          />
        </Spin>
      )}
    </Wrapper>
  );
};
