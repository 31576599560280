// Lib
import { FC } from "react";
// Hooks
import { useExport } from "hooks";
// Icons
import { DownloadIcon } from "icons";
// Styled
import { ExportButton } from "./styled";

interface ExportProps {
  type?: "Heading" | "Base";
  fileName: string;
  isLoading: boolean;
  isDataLoading: boolean;
  isDisabled: boolean;
  columns: Record<string, unknown>[];
  getExportData: () => Promise<Record<string, unknown>[]>;
  dateFormat?: string;
}

export const Export: FC<ExportProps> = ({
  type = "Heading",
  isLoading,
  isDataLoading,
  isDisabled,
  fileName,
  columns,
  getExportData,
  dateFormat,
}) => {
  const { downloadCSV } = useExport();

  const Component = ExportButton[type];

  return (
    <Component
      icon={<DownloadIcon />}
      loading={isLoading}
      disabled={isDisabled}
      onClick={() =>
        downloadCSV({
          fileName,
          isDataLoading,
          columns,
          getExportData,
          dateFormat,
        })
      }
    >
      Export
    </Component>
  );
};
