// Lib
import { FC, useCallback, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
// Api
import {
  useGetReviewQuery,
  useUpdateReviewMutation,
} from "rtkQuery/query/reviewsAPI";
// Hooks
import { useNotification, useViewport } from "hooks";
// Types
import { ReviewStatus, ReviewStatusLabels } from "types/reviews";
import { ReviewForm } from "./types";
// Constants
import { ADMIN_ROUTES, rtkQueryParams } from "consts";
// Helpers
import { dateTransform } from "helpers/dataHelpers";
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
import { PersonIcon, RightBurgerMenuIcon, StarIcon } from "icons";
// Components
import { Rate } from "antd";
import {
  ArrowBackLink,
  Badge,
  DropDown,
  FilledButtonLink,
  SummaryField,
} from "components";
import { TextArea } from "components/Form";
import { ConfirmDialog } from "components/Modals";
import { RejectModal } from "../components";
// Styled
import { Button } from "styled/Buttons";
import { Typography } from "styled/Typography";
import {
  ContentBox,
  FlexContainer,
  PageHeadingContainer,
  PageTitleContainer,
  SummaryItemsGrid,
} from "styled/Box";

import { reviewBadgeColors } from "../../Main/config";

import { defaultValues } from "./defaultValues";

export const ReviewDetails: FC = () => {
  const { id } = useParams();

  const { isDesktop } = useViewport();

  const { openNotification } = useNotification();

  const navigate = useNavigate();

  const [isRejectModal, setIsRejectModal] = useState(false);
  const [isConfirmModal, setIsConfirmModal] = useState(false);

  const { data, isLoading, error } = useGetReviewQuery({ id }, rtkQueryParams);
  const [update, { isLoading: isUpdateLoading }] = useUpdateReviewMutation();

  const { handleSubmit, control, reset, setValue } = useForm<ReviewForm>({
    defaultValues,
  });

  useEffect(() => {
    if (!data) {
      return;
    }

    reset({ rate: Number(data?.rating) || 0, review: data?.comment });
  }, [data]);

  useEffect(() => {
    if (error) {
      errorHandler({ error, openNotification });
    }
  }, [error]);

  const onSubmit: SubmitHandler<ReviewForm> = async data => {
    const { reason, status } = data;
    try {
      await update({ id, reason, status }).unwrap();

      openNotification({ message: `Review successfuly ${data.status}` });

      setIsRejectModal(false);
      setIsConfirmModal(false);
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const handleApprove = async () => {
    setValue("status", ReviewStatus.APPROVED);
    setValue("reason", "");
    await handleSubmit(onSubmit)();
  };

  const handleReject = useCallback(async (reason: string) => {
    setValue("status", ReviewStatus.REJECTED);
    setValue("reason", reason);
    await handleSubmit(onSubmit)();
  }, []);

  const handleOpenConfirmModal = async () => {
    setIsConfirmModal(true);
  };

  const handleCloseConfirmModal = useCallback(() => {
    setIsConfirmModal(false);
  }, []);

  const handleOpenRejectModal = async () => {
    setIsRejectModal(true);
  };

  const handleCloseRegectModal = useCallback(() => {
    setIsRejectModal(false);
  }, []);

  const handleCustomerClick = useCallback(() => {
    navigate(`${ADMIN_ROUTES.CUSTOMERS.path}/${data?.customerId}`);
  }, [data]);

  const handleAdminClick = useCallback(() => {
    navigate(`/users/${data?.initiatorId}`);
  }, [data]);

  const dropDownMenuItems = [
    {
      key: "3",
      label: "Reject",
      onClick: handleOpenRejectModal,
    },
    {
      key: "4",
      label: "Approve",
      onClick: handleApprove,
    },
  ];

  const isPendingStatus = data?.status === ReviewStatus.REVIEW;

  return (
    <>
      <PageHeadingContainer $margin="0 0 24px">
        <PageTitleContainer $column>
          <ArrowBackLink
            title="Back to Reviews"
            path={ADMIN_ROUTES.REVIEWS.path}
          />
          <FlexContainer $margin="20px 0 0 0">
            <Typography.H1>Review Details</Typography.H1>
          </FlexContainer>
        </PageTitleContainer>

        {isDesktop ? (
          <FlexContainer $gap={10}>
            {isPendingStatus && (
              <>
                <Button.Heading status="danger" onClick={handleOpenRejectModal}>
                  Reject
                </Button.Heading>

                <Button.Heading type="primary" onClick={handleOpenConfirmModal}>
                  Approve
                </Button.Heading>
              </>
            )}
          </FlexContainer>
        ) : (
          isPendingStatus && (
            <DropDown items={dropDownMenuItems} trigger={["click"]}>
              <Button.SquaredIcon icon={<RightBurgerMenuIcon />} />
            </DropDown>
          )
        )}
      </PageHeadingContainer>

      <ContentBox $column $margin="0 0 16px 0">
        <Typography.H2>Summary</Typography.H2>

        <SummaryItemsGrid>
          <SummaryField label="ID" isLoading={isLoading} value={data?.id} />

          <SummaryField
            label="Customer"
            isLoading={isLoading}
            value={
              <FilledButtonLink
                icon={<PersonIcon />}
                title={data?.firstName}
                onClick={handleCustomerClick}
              />
            }
          />

          <SummaryField
            label="Status"
            isLoading={isLoading}
            value={
              <Badge
                bgColor={reviewBadgeColors[data?.status]}
                title={ReviewStatusLabels[data?.status]}
              />
            }
          />

          {!!data?.adminFirstName && (
            <SummaryField
              label="User Initiated"
              isLoading={isLoading}
              value={
                <FilledButtonLink
                  icon={<PersonIcon />}
                  title={data?.adminFirstName}
                  onClick={handleAdminClick}
                />
              }
            />
          )}

          <SummaryField
            label="Product"
            isLoading={isLoading}
            value={data?.productName}
          />

          <SummaryField
            label="Created At"
            isLoading={isLoading}
            value={dateTransform({
              date: data?.createdAt,
            })}
          />

          <SummaryField
            label="Updated At"
            isLoading={isLoading}
            value={dateTransform({
              date: data?.updatedAt,
            })}
          />
        </SummaryItemsGrid>
      </ContentBox>

      <ContentBox $column $margin="0 0 16px 0">
        <Typography.H2>Details</Typography.H2>

        <FlexContainer
          as="form"
          $fullwidth
          $column
          $gap={12}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div>
            <Typography.Label>Rate</Typography.Label>
            <Controller
              name="rate"
              control={control}
              render={({ field, fieldState }) => (
                <Rate disabled {...field} {...fieldState} />
              )}
            />
          </div>

          <Controller
            name="review"
            control={control}
            render={({ field, fieldState }) => (
              <TextArea
                label="Review"
                isDisabled
                {...field}
                fieldState={fieldState}
              />
            )}
          />
        </FlexContainer>
      </ContentBox>

      {data?.reason && (
        <ContentBox $column>
          <Typography.H2>Reject Reason</Typography.H2>

          <TextArea isDisabled={true} value={data?.reason} />
        </ContentBox>
      )}

      <RejectModal
        open={isRejectModal}
        isLoading={isUpdateLoading}
        onClose={handleCloseRegectModal}
        onSave={handleReject}
      />

      <ConfirmDialog
        open={!!isConfirmModal}
        isLoading={isUpdateLoading}
        Icon={StarIcon}
        message="Review will be approved"
        description="Are you sure you want to approve this review?"
        onCancel={handleCloseConfirmModal}
        firstCTAButton={{
          title: "Approve",
          type: "primary",
          loading: isUpdateLoading,
          onClick: handleApprove,
        }}
      />
    </>
  );
};
