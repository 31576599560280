export type SetCoreSettingsRequestDto = {
  instantOrderCancellationTime?: number;
  cancelTimeAfterStart?: number;
  defaultPreparationTime?: number;
  defaultDeliveryTime?: number;
};

export enum WeekStartDayValue {
  Monday = "monday",
  Tuesday = "tuesday",
  Wednesday = "wednesday",
  Thursday = "thursday",
  Friday = "friday",
  Saturday = "saturday",
  Sunday = "sunday",
}

export type RewardsSettingsResponseDto = {
  weekStartDay: WeekStartDayValue;
  isRewardsEnabled: boolean;
  bonusCoins: number;
  metricsMinRequired: number;
  stepMetricSettings: {
    requiredStepsDays: number;
    requiredStepsPerDay: number;
  };
  exerciseMetricSettings: {
    minutesCount: number;
  };
  diaryMetricSettings: {
    daysCount: number;
  };
};
