// Lib
import { FC } from "react";
// Api
// Hooks
// Actions
// Selectors
// Types
// Theme
// Constants
// Helpers
// Utils
// Icons
// Layouts
// Components
import { Select as FormSelect } from "components/Form";
import { Skeleton } from "antd";
import { WeekStartDayValue } from "types/coreSettings";
// Styled

const dayOptions = [
  { value: WeekStartDayValue.Sunday, label: "Sunday" },
  { value: WeekStartDayValue.Monday, label: "Monday" },
  { value: WeekStartDayValue.Tuesday, label: "Tuesday" },
  { value: WeekStartDayValue.Wednesday, label: "Wednesday" },
  { value: WeekStartDayValue.Thursday, label: "Thursday" },
  { value: WeekStartDayValue.Friday, label: "Friday" },
  { value: WeekStartDayValue.Saturday, label: "Saturday" },
];

interface SelectProps {
  isLoading: boolean;
  canUpdate: boolean;
  data: string | WeekStartDayValue;
  onChange: (value: WeekStartDayValue) => void;
}

export const Select: FC<SelectProps> = ({
  isLoading,
  canUpdate,
  data,
  onChange,
}) => {
  if (data === undefined && isLoading) {
    return <Skeleton.Input active />;
  }

  if (data === undefined) {
    return null;
  }

  return (
    <FormSelect
      style={{ width: 133 }}
      options={dayOptions}
      loading={isLoading}
      disabled={!canUpdate}
      value={data}
      onChange={onChange}
    />
  );
};
