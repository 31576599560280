import { FC } from "react";

import { SvgIconConstituentValues } from "types/common";

export const StarIcon: FC<SvgIconConstituentValues> = ({
  fillColor,
  fillColor2,
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M7.52439 2.79702C7.67407 2.33637 8.32577 2.33637 8.47545 2.79702L9.38442 5.59456C9.45136 5.80057 9.64334 5.94005 9.85995 5.94005H12.8015C13.2858 5.94005 13.4872 6.55986 13.0953 6.84456L10.7156 8.57353C10.5404 8.70085 10.467 8.92654 10.534 9.13255L11.443 11.9301C11.5926 12.3907 11.0654 12.7738 10.6735 12.4891L8.29381 10.7601C8.11857 10.6328 7.88127 10.6328 7.70603 10.7601L5.3263 12.4891C4.93444 12.7738 4.4072 12.3907 4.55688 11.9301L5.46585 9.13255C5.53279 8.92654 5.45946 8.70085 5.28422 8.57353L2.90449 6.84456C2.51263 6.55986 2.71402 5.94005 3.19838 5.94005H6.13989C6.3565 5.94005 6.54848 5.80057 6.61542 5.59456L7.52439 2.79702Z"
      fill={fillColor || "#B67C4A"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.066 4.61393L8.47553 2.79666C8.32586 2.33601 7.67415 2.33601 7.52448 2.79666L6.6155 5.5942C6.54856 5.80021 6.35659 5.93969 6.13997 5.93969H3.19847C2.71411 5.93969 2.51272 6.5595 2.90458 6.8442L5.2843 8.57317C5.45955 8.70049 5.53287 8.92618 5.46594 9.13219L4.55696 11.9297C4.40729 12.3904 4.93453 12.7734 5.32638 12.4887L6.04018 11.9701C6.25903 9.17715 7.36057 6.63215 9.066 4.61393Z"
      fill={fillColor2 || "#A65D1E"}
    />
  </svg>
);
