// Lib
import { FC, useEffect, useRef, useState } from "react";
// Api
import { useGetCustomerMenuByLocationQuery } from "rtkQuery/query/customersAPI";
// Hooks
import { useNotification, useViewport } from "hooks";
// Types
import {
  CustomerMenuAddonModifier,
  CustomerMenuProduct,
} from "types/customers";
import {
  AddonsModifiers,
  CompleteProductData,
  MenuStatuses,
} from "../../types";
// Theme
// Constants
import { rtkQueryParams } from "consts";
// Helpers
import { fixedDigitsValue } from "helpers/dataHelpers";
import { getMenuStatuses, getSubtotal } from "../../helpers";
// Utils
import { errorHandler } from "utils/errorHandler";
// Components
import { Modal } from "components";
import {
  Checkbox,
  Input,
  InputQuantity,
  SearchProductsSelect,
  TextArea,
} from "components/Form";
// Styled
import { FlexContainer } from "styled/Box";
import { Typography } from "styled/Typography";
import { Button } from "styled/Buttons";
import { InputsGridContainer, Title } from "./styled";
import { OrderExternalService } from "types/orders";

interface AddItemProps {
  locationId: string;
  itemData: boolean | CompleteProductData;
  setMenuId: (id: string) => void;
  setMenuStatuses: (data: MenuStatuses) => void;
  onAddItem: (data: CompleteProductData) => void;
  onClose: () => void;
}

export const AddItem: FC<AddItemProps> = ({
  locationId,
  itemData,
  setMenuId,
  setMenuStatuses,
  onAddItem,
  onClose,
}) => {
  const { openNotification } = useNotification();

  const { isMobile } = useViewport();

  const isEdit = !!itemData && typeof itemData !== "boolean";

  const [products, setProducts] = useState<CustomerMenuProduct[]>([]);

  const { data, error } = useGetCustomerMenuByLocationQuery(
    { id: locationId, query: { service: OrderExternalService.talabat } },
    { ...rtkQueryParams, skip: !locationId },
  );

  useEffect(() => {
    if (error) {
      errorHandler({ error, openNotification });
      setProducts([]);
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      const products = data.categories.reduce(
        (acc: CustomerMenuProduct[], el) => {
          return acc.concat(el.products);
        },
        [],
      );

      const statuses = getMenuStatuses(data);

      setMenuId(data.id);
      setProducts(products);
      setMenuStatuses(statuses);
    }
  }, [data]);

  const addonsListRef = useRef<HTMLDivElement>();
  const [addonsHeight, setAddonsHeight] = useState(0);

  const [quantity, setQuantity] = useState(1);
  const [remark, setRemark] = useState("");

  const [selectedProduct, setSelectedProduct] =
    useState<CustomerMenuProduct | null>(null);

  const [selectedModifiers, setSelectedModifiers] = useState<AddonsModifiers>({
    addon: [],
    modifier: [],
  });

  useEffect(() => {
    if (selectedProduct?.id) {
      setAddonsHeight(addonsListRef?.current?.clientHeight);
      return;
    }

    if (!selectedProduct?.id) {
      setAddonsHeight(0);
    }
  }, [selectedProduct?.id, itemData, selectedModifiers]);

  useEffect(() => {
    if (!itemData) {
      setQuantity(1);
      setRemark("");
      setSelectedProduct(null);
      setSelectedModifiers({
        addon: [],
        modifier: [],
      });
      return;
    }

    if (typeof itemData !== "boolean") {
      setSelectedModifiers(itemData.addonsModifiers);
      setRemark(itemData.remark);
      setQuantity(itemData.quantity);
      setSelectedProduct(itemData.product);
    }
  }, [itemData]);

  const onClear = () => {
    setQuantity(1);
    setRemark("");
    setSelectedProduct(null);
    setSelectedModifiers({
      addon: [],
      modifier: [],
    });
  };

  const onCheck = (
    addonModifier: CustomerMenuAddonModifier,
    value: boolean,
  ) => {
    if (value) {
      setSelectedModifiers(prev => {
        if (prev[addonModifier.type]?.find(el => el.id === addonModifier.id)) {
          return prev;
        }
        return {
          ...prev,
          [addonModifier.type]: prev[addonModifier.type].concat({
            ...addonModifier,
            quantity: 1,
          }),
        };
      });

      return;
    }

    setSelectedModifiers(prev => {
      if (prev[addonModifier.type]?.find(el => el.id === addonModifier.id)) {
        return {
          ...prev,
          [addonModifier.type]: prev[addonModifier.type].filter(
            el => el.id !== addonModifier.id,
          ),
        };
      }
      return prev;
    });
  };

  const handleAddItem = () => {
    const uniqId =
      !!itemData && typeof itemData !== "boolean"
        ? itemData?.uniqId
        : undefined;

    onAddItem({
      uniqId,
      product: selectedProduct,
      addonsModifiers: selectedModifiers,
      quantity,
      remark,
    });
  };

  const setModifierQuantity = (
    addonModifier: CustomerMenuAddonModifier,
    quantity: number,
  ) => {
    if (quantity) {
      setSelectedModifiers(prev => {
        const addonModifierItem = prev[addonModifier.type]?.find(
          el => el.id === addonModifier.id,
        );

        if (addonModifierItem) {
          return {
            ...prev,
            [addonModifier.type]: prev[addonModifier.type]
              ?.filter(el => el.id !== addonModifier.id)
              .concat({ ...addonModifier, quantity }),
          };
        }
      });

      return;
    }
  };

  const options =
    products?.map(i => ({
      label: i.name,
      value: i.id,
      subdata: i,
      disabled: i.isSnoozed,
    })) || [];

  const { subTotalFiat } = getSubtotal({
    product: selectedProduct,
    addonsModifiers: selectedModifiers,
    quantity,
    remark,
  });

  return (
    <Modal width={560} title="Add Item" open={!!itemData} onClose={onClose}>
      <FlexContainer $fullwidth $column $gap={24}>
        <FlexContainer $column $gap={16}>
          <SearchProductsSelect
            required
            label="Food name"
            placeholder="Search food name"
            disabled={isEdit}
            value={selectedProduct?.id}
            options={options}
            optionRender={option => (
              <FlexContainer
                $align="center"
                $justify="space-between"
                $padding="8px 0"
              >
                <Typography.Title>{option.label}</Typography.Title>

                <Typography.Title>
                  {fixedDigitsValue(option.data.subdata.fiatCentsPrice, 3)} KD
                </Typography.Title>
              </FlexContainer>
            )}
            onSelect={(_value, option) =>
              setSelectedProduct(option.subdata || null)
            }
            onClear={onClear}
          />

          <InputsGridContainer>
            <Input
              label="Price"
              disabled
              value={`${
                selectedProduct?.fiatCentsPrice
                  ? fixedDigitsValue(selectedProduct.fiatCentsPrice, 3)
                  : 0
              } KD`}
            />

            <InputQuantity
              label="Quantity"
              value={quantity}
              onChange={(value: number) => setQuantity(value)}
            />
          </InputsGridContainer>

          <TextArea
            label="Note"
            placeholder="Enter the Note"
            value={remark}
            onChange={e => setRemark(e.target.value)}
          />
        </FlexContainer>

        <div
          style={{
            height: isEdit ? "auto" : addonsHeight,
            overflow: "hidden",
            transition: "height .25s",
          }}
        >
          <FlexContainer ref={addonsListRef} $fullwidth $column $gap={24}>
            {!!selectedProduct?.addons?.length && (
              <FlexContainer $column $gap={16}>
                <Title>Addons</Title>

                {selectedProduct.addons.map(el => {
                  const selected = selectedModifiers.addon.find(
                    a => a.id === el.id,
                  );

                  const isChecked = !!selected;

                  return (
                    <FlexContainer
                      key={el.id}
                      $fullwidth
                      $column={isMobile}
                      $gap={10}
                      $justify="space-between"
                      $align="flex-start"
                    >
                      <FlexContainer $column>
                        <Checkbox
                          label={el.name}
                          checked={isChecked}
                          onChange={e => onCheck(el, e.target.checked)}
                        />

                        {!isMobile && isChecked && (
                          <FlexContainer $margin="4px 0 0 26px">
                            <InputQuantity
                              value={selected?.quantity}
                              onChange={(value: number) =>
                                setModifierQuantity(el, value)
                              }
                            />
                          </FlexContainer>
                        )}
                      </FlexContainer>

                      <FlexContainer $margin={isMobile ? "4px 0 0 26px" : "0"}>
                        <Typography.Title>{`${fixedDigitsValue(
                          el.fiatCentsPrice,
                          3,
                        )} KD`}</Typography.Title>
                      </FlexContainer>

                      {isMobile && isChecked && (
                        <FlexContainer $margin="4px 0 0 26px">
                          <InputQuantity
                            value={selected?.quantity}
                            onChange={(value: number) =>
                              setModifierQuantity(el, value)
                            }
                          />
                        </FlexContainer>
                      )}
                    </FlexContainer>
                  );
                })}
              </FlexContainer>
            )}

            {!!selectedProduct?.modifiers?.length && (
              <FlexContainer $column $gap={16}>
                <Title>Modifiers</Title>

                {selectedProduct.modifiers.map(el => (
                  <FlexContainer
                    key={el.id}
                    $fullwidth
                    $justify="space-between"
                    $align="center"
                  >
                    <Checkbox
                      checked={
                        !!selectedModifiers.modifier.find(m => m.id === el.id)
                      }
                      label={el.name}
                      onChange={e => onCheck(el, e.target.checked)}
                    />
                  </FlexContainer>
                ))}
              </FlexContainer>
            )}
          </FlexContainer>
        </div>
      </FlexContainer>

      <FlexContainer
        $fullwidth
        $align="center"
        $justify="space-between"
        $padding="24px 0 0 0"
        $column={isMobile}
        $gap={isMobile && 16}
      >
        <FlexContainer $column $gap={8} $fullwidth={isMobile}>
          <Typography.Title>Subtotal</Typography.Title>

          <Title>{subTotalFiat} KD</Title>
        </FlexContainer>

        <FlexContainer $gap={8} $column={isMobile} $fullwidth={isMobile}>
          <Button.Heading onClick={onClose}>Cancel</Button.Heading>

          <Button.Heading
            type="primary"
            disabled={!selectedProduct}
            onClick={handleAddItem}
          >
            {isEdit ? "Save" : "Add"} Item
          </Button.Heading>
        </FlexContainer>
      </FlexContainer>
    </Modal>
  );
};
