import { NOTIFICATIONS } from "consts/notifications";
import { WeekStartDayValue } from "types/coreSettings";

export enum RewardsField {
  WeekStartDay = "weekStartDay",
  IsRewardsEnabled = "isRewardsEnabled",
  BonusCoins = "bonusCoins",
  MetricsMinRequired = "metricsMinRequired",
  StepMetricRequiredStepsDays = "requiredStepsDays",
  StepMetricRequiredStepsPerDay = "requiredStepsPerDay",
  ExerciseMetricMinutesCount = "minutesCount",
  DiaryMetricDaysCount = "daysCount",
}

export const notificationMessage = {
  [RewardsField.WeekStartDay]: NOTIFICATIONS.REWARDS,
  [RewardsField.IsRewardsEnabled]: NOTIFICATIONS.REWARDS,
  [RewardsField.BonusCoins]: NOTIFICATIONS.REWARDS,
  [RewardsField.MetricsMinRequired]: NOTIFICATIONS.REWARDS,
  [RewardsField.StepMetricRequiredStepsDays]: NOTIFICATIONS.REWARDS,
  [RewardsField.StepMetricRequiredStepsPerDay]: NOTIFICATIONS.REWARDS,
  [RewardsField.ExerciseMetricMinutesCount]: NOTIFICATIONS.REWARDS,
  [RewardsField.DiaryMetricDaysCount]: NOTIFICATIONS.REWARDS,
};

export type StateValues = {
  [RewardsField.IsRewardsEnabled]: undefined | boolean;
  [RewardsField.WeekStartDay]: undefined | WeekStartDayValue;
  [RewardsField.BonusCoins]: undefined | number;
  [RewardsField.MetricsMinRequired]: undefined | number;
  [RewardsField.StepMetricRequiredStepsDays]: undefined | number;
  [RewardsField.StepMetricRequiredStepsPerDay]: undefined | number;
  [RewardsField.ExerciseMetricMinutesCount]: undefined | number;
  [RewardsField.DiaryMetricDaysCount]: undefined | number;
};

export const initialState: StateValues = {
  [RewardsField.WeekStartDay]: undefined,
  [RewardsField.IsRewardsEnabled]: undefined,
  [RewardsField.BonusCoins]: undefined,
  [RewardsField.MetricsMinRequired]: undefined,
  [RewardsField.StepMetricRequiredStepsDays]: undefined,
  [RewardsField.StepMetricRequiredStepsPerDay]: undefined,
  [RewardsField.ExerciseMetricMinutesCount]: undefined,
  [RewardsField.DiaryMetricDaysCount]: undefined,
};

export type LoadingStateValues = Record<keyof StateValues, boolean>;

export const initialLoadingState: LoadingStateValues = {
  [RewardsField.WeekStartDay]: false,
  [RewardsField.IsRewardsEnabled]: false,
  [RewardsField.BonusCoins]: false,
  [RewardsField.MetricsMinRequired]: false,
  [RewardsField.StepMetricRequiredStepsDays]: false,
  [RewardsField.StepMetricRequiredStepsPerDay]: false,
  [RewardsField.ExerciseMetricMinutesCount]: false,
  [RewardsField.DiaryMetricDaysCount]: false,
};

export type ConfirmModalType = {
  key: RewardsField;
  value: boolean | number | WeekStartDayValue;
};
