import { TABLE_DATE } from "consts";
import { dateTransform } from "helpers/dataHelpers";

function extractValues(
  keys: string[],
  data: Record<string, unknown>[],
  dateFormat?: string,
) {
  const rowsArray = data.map(d => {
    const rows = keys.map(k => {
      if (k === "createdAt" || k === "updatedAt") {
        return dateTransform({
          date: d[k] as string,
          format: dateFormat || TABLE_DATE,
        });
      }
      return d[k];
    });

    return rows;
  });

  return rowsArray;
}

export const useExport = () => {
  const convertToCSV = (arr: unknown[][]) => {
    return arr
      .map(row =>
        row
          .map(data => {
            if (typeof data === "string" && data.includes(",")) {
              return `"${data}"`;
            }

            return data;
          })
          .join(","),
      )
      .join("\n");
  };

  const downloadCSV = async (config: {
    fileName: string;
    isDataLoading: boolean;
    columns: Record<string, unknown>[];
    getExportData: () => Promise<Record<string, unknown>[]>;
    dateFormat?: string;
  }) => {
    if (config.isDataLoading) {
      return;
    }

    const data = await config.getExportData();

    const titles = config.columns.map(c => c?.title);

    const keys = config.columns
      .map(c => c?.key)
      .filter(el => typeof el === "string") as string[];

    const rowsArray = extractValues(keys, data, config?.dateFormat);

    const csvData = [titles, ...rowsArray];

    const csvContent =
      "data:text/csv;charset=utf-8," +
      encodeURIComponent(convertToCSV(csvData));
    const link = document.createElement("a");
    link.setAttribute("href", csvContent);
    link.setAttribute("download", `${config.fileName}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return { downloadCSV };
};
