import { Payment } from "./components/PaymentSettings/config";
import { Field } from "./components/AuthSettings/config";
import { Referral } from "./components/ReferralsSettings/config";
import { ScheduleOrder } from "./components/ScheduleOrdersSettings/config";
import { RewardsField } from "./components/Rewards/config";

type ModalKey = Field | Payment | Referral | ScheduleOrder | RewardsField;

export const getConfirmModalTitle = ({
  key,
  value,
}: {
  key: ModalKey;
  value: boolean | number;
}): string => {
  switch (key) {
    case RewardsField.IsRewardsEnabled:
      return value
        ? "Rewards section will be displayed in the mobile application"
        : "Rewards section will be hidden in the mobile application";

    case Field.IsComments:
      return value
        ? "Comments section will be displayed in the mobile application"
        : "Comments section will be hidden in the mobile application";

    case Field.IsExternalFiatCentsPriceVisible:
      return value
        ? "All KWD prices will be displayed in the mobile application"
        : "All KWD prices will be hidden in the mobile application";

    case Field.AllowWaitingList:
      return value
        ? "All new unregistered users will see the waiting list-related screens on mobile, appear in the Waiting list with inactive status"
        : "All new users will appear immediately after signing up in the Users with active status, won't see any of the waiting list-related screens on mobile";

    case Field.AllowCoupons:
      return value
        ? "Purchase coupons will be enabled"
        : "Purchase coupons will be disabled";

    case Field.AllowTopUpCoupons:
      return value
        ? "Top-up coupons will be enabled"
        : "Top-up coupons will be disabled";

    case Field.AllowMenuItemsGrid:
      return value
        ? "The menu in the mobile application will be displayed as a grid"
        : "The menu in the mobile application will be displayed as a list";

    case Field.IsAppAccessible:
      return value
        ? "Access to the application will be open to users"
        : "Access to the application will be closed for users";

    case Field.IsApplePayAllowed:
      return value
        ? "Payment of the order via applepay will be enabled"
        : "Payment of the order via applepay will be disabled";

    case Field.IsTalyAllowed:
      return value
        ? "Payment of the order via taly will be enabled"
        : "Payment of the order via taly will be disabled";

    case Field.IsTamaraAllowed:
      return value
        ? "Payment of the order via tamara will be enabled"
        : "Payment of the order via tamara will be disabled";

    case Field.IsTabbyAllowed:
      return value
        ? "Payment of the order via Tabby will be enabled"
        : "Payment of the order via Tabby will be disabled";

    case Payment.IsCashPayment:
      return value
        ? "Cash Payment will be enabled"
        : "Cash Payment will be disabled";

    case Payment.isDeliveryFeeAllowed:
      return value
        ? "Delivery Fee for users with expired coin package will be enabled"
        : "Delivery Fee for users with expired coin package will be disabled";

    case Referral.IsReferralBonus:
      return value
        ? "Referral Links will be enabled"
        : "Referral Links will be disabled";

    case ScheduleOrder.IsScheduleOrders:
      return value
        ? "Orders Schedule will be enabled"
        : "Orders Schedule will be disabled";

    default:
      return "";
  }
};

export const getWarningModalTitle = ({ key }: { key: ModalKey }): string => {
  switch (key) {
    case Payment.IsCashPayment:
      return "Cash Payment will not be enabled if Delivery Fee for users with expired coin package is enabled";
    case Payment.isDeliveryFeeAllowed:
      return "Delivery Fee for users with expired coin package will not be enabled if Cash Payment is enabled";

    default:
      return "";
  }
};

export const getWarningModalDescription = ({
  key,
}: {
  key: ModalKey;
}): string => {
  switch (key) {
    case Payment.IsCashPayment:
      return "Disable Delivery Fee for users with expired coin package to use Cash Payments";
    case Payment.isDeliveryFeeAllowed:
      return "Disable Cash Payments to use Delivery Fee";

    default:
      return "";
  }
};
