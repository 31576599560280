// Lib
import { FC, useCallback, useEffect, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
// Api
import {
  useGetOrdersQuery,
  useGetScheduledOrdersQuery,
  useGetTalabatOrdersQuery,
  useLazyGetOrdersQuery,
  useLazyGetScheduledOrdersQuery,
  useLazyGetTalabatOrdersQuery,
} from "rtkQuery/query/ordersAPI";
import { useGetLocationsQuery } from "rtkQuery/query/locationsAPI";
// Hooks
import {
  useExport,
  useNotification,
  usePermissions,
  useTable,
  useViewport,
} from "hooks";
// Types
import { ETable } from "types/tableFilters";
// Constants
import { ADMIN_ROUTES, rtkQueryParams, SHORT_TABLE_DATE } from "consts";
// Icons
import { PlusIcon, RightBurgerMenuIcon } from "icons";
// Utils
import { errorHandler } from "utils/errorHandler";
// Theme
import { theme } from "theme";
// Components
import { DropDown, Export, Pagination, Tabs } from "components";
import { GeneralTab, ScheduledTab, TalabatTab } from "./components";
// Styled
import { FlexContainer, PageWrapper } from "styled/Box";
import { Typography } from "styled/Typography";
import { Button } from "styled/Buttons";

import { pageSizeOptions, TabKey } from "./config";
import { columns as GeneralTabColumns } from "./components/GeneralTab/config";
import { columns as ScheduledTabColumns } from "./components/ScheduledTab/config";
import { columns as TalabatTabColumns } from "./components/TalabatTab/config";

export const Orders: FC = () => {
  const navigate = useNavigate();
  const { openNotification } = useNotification();

  const { isDesktop } = useViewport();
  const { downloadCSV } = useExport();

  const { canOrdersCreate } = usePermissions();

  const [searchParams, setSearchParams] = useSearchParams();

  const activeTab = searchParams.get("type") || TabKey.General;

  const setActiveTab = (type: TabKey) => {
    setSearchParams({ type });
  };

  const [getExportOrdersData, { isFetching: isExportOrdersLoading }] =
    useLazyGetOrdersQuery();
  const [getExportTalabatOrdersData, { isFetching: isExportTalabatLoading }] =
    useLazyGetTalabatOrdersQuery();
  const [
    getExportScheduledOrdersData,
    { isFetching: isExportScheduledLoading },
  ] = useLazyGetScheduledOrdersQuery();

  const { data: locations, error: locationsError } = useGetLocationsQuery(
    null,
    rtkQueryParams,
  );

  const {
    sortingOrder: ordersSortingOrder,
    sortBy: ordersSortBy,
    page: ordersPage,
    limit: ordersLimit,
    debouncedSearch: ordersDebouncedSearch,
    debouncedFiltersQuery: ordersDebouncedFiltersQuery,
    setPage: setOrdersPage,
    setLimit: setOrdersLimit,
    handleSort: handleOrdersSort,
  } = useTable({ name: ETable.Orders, removeQueryParams: true });

  const {
    sortingOrder: scheduledOrdersSortingOrder,
    sortBy: scheduledOrdersSortBy,
    page: scheduledOrdersPage,
    limit: scheduledOrdersLimit,
    debouncedSearch: scheduledOrdersDebouncedSearch,
    debouncedFiltersQuery: scheduledOrdersDebouncedFiltersQuery,
    setPage: setScheduledOrdersPage,
    setLimit: setScheduledOrdersLimit,
    handleSort: handleScheduledOrdersSort,
  } = useTable({
    name: ETable.ScheduledOrders,
    removeQueryParams: true,
  });

  const {
    sortingOrder: talabatOrdersSortingOrder,
    sortBy: talabatOrdersSortBy,
    page: talabatOrdersPage,
    limit: talabatOrdersLimit,
    debouncedSearch: talabatOrdersDebouncedSearch,
    debouncedFiltersQuery: talabatOrdersDebouncedFiltersQuery,
    setPage: setTalabatOrdersPage,
    setLimit: setTalabatOrdersLimit,
    handleSort: handleTalabatOrdersSort,
  } = useTable({
    name: ETable.TalabatOrders,
    removeQueryParams: true,
  });

  const {
    data: ordersData,
    isFetching: isOrdersLoading,
    error: ordersError,
  } = useGetOrdersQuery(
    {
      query: {
        ...(ordersDebouncedFiltersQuery && ordersDebouncedFiltersQuery),
        page: ordersPage,
        limit: ordersLimit,
        sortBy: ordersSortBy,
        sortingOrder: ordersSortingOrder,
        search: ordersDebouncedSearch,
      },
    },
    { ...rtkQueryParams, skip: activeTab !== TabKey.General },
  );

  const {
    data: talabatOrdersData,
    isFetching: isTalabatOrdersLoading,
    error: talabatOrdersError,
  } = useGetTalabatOrdersQuery(
    {
      query: {
        ...(talabatOrdersDebouncedFiltersQuery &&
          talabatOrdersDebouncedFiltersQuery),
        page: talabatOrdersPage,
        limit: talabatOrdersLimit,
        sortBy: talabatOrdersSortBy,
        sortingOrder: talabatOrdersSortingOrder,
        search: talabatOrdersDebouncedSearch,
      },
    },
    { ...rtkQueryParams, skip: activeTab !== TabKey.Talabat },
  );

  const {
    data: scheduledOrdersData,
    isLoading: isScheduledOrdersLoading,
    isFetching: isScheduledOrdersFetching,
    error: scheduledOrdersError,
  } = useGetScheduledOrdersQuery(
    {
      query: {
        ...(scheduledOrdersDebouncedFiltersQuery &&
          scheduledOrdersDebouncedFiltersQuery),
        page: scheduledOrdersPage,
        limit: scheduledOrdersLimit,
        sortBy: scheduledOrdersSortBy,
        sortingOrder: scheduledOrdersSortingOrder,
        search: scheduledOrdersDebouncedSearch,
      },
    },
    {
      ...rtkQueryParams,
      pollingInterval: 10000,
      skip: activeTab !== TabKey.Scheduled,
    },
  );

  useEffect(() => {
    if (ordersError) {
      errorHandler({ error: ordersError, openNotification });
    }
  }, [ordersError]);

  useEffect(() => {
    if (scheduledOrdersError) {
      errorHandler({ error: scheduledOrdersError, openNotification });
    }
  }, [scheduledOrdersError]);

  useEffect(() => {
    if (talabatOrdersError) {
      errorHandler({ error: talabatOrdersError, openNotification });
    }
  }, [talabatOrdersError]);

  useEffect(() => {
    if (locationsError) {
      errorHandler({ error: locationsError, openNotification });
    }
  }, [locationsError]);

  const createNewOrder = useCallback(() => {
    navigate(`${ADMIN_ROUTES.ORDERS.path}/create`);
  }, []);

  const createNewTalabatOrder = useCallback(() => {
    navigate(`${ADMIN_ROUTES.ORDERS.path}/talabat/create`);
  }, []);

  const exportDataFunction = useMemo(
    () => ({
      [TabKey.General]: getExportOrdersData,
      [TabKey.Scheduled]: getExportScheduledOrdersData,
      [TabKey.Talabat]: getExportTalabatOrdersData,
    }),
    [],
  );

  const getExportData = async (): Promise<Record<string, unknown>[]> => {
    const getExport = exportDataFunction[activeTab];

    const exportQueryParams = {
      [TabKey.General]: {
        ...(ordersDebouncedFiltersQuery && ordersDebouncedFiltersQuery),
        limit: ordersLimit,
        sortBy: ordersSortBy,
        sortingOrder: ordersSortingOrder,
        search: ordersDebouncedSearch,
      },
      [TabKey.Scheduled]: {
        ...(scheduledOrdersDebouncedFiltersQuery &&
          scheduledOrdersDebouncedFiltersQuery),
        limit: scheduledOrdersLimit,
        sortBy: scheduledOrdersSortBy,
        sortingOrder: scheduledOrdersSortingOrder,
        search: scheduledOrdersDebouncedSearch,
      },
      [TabKey.Talabat]: {
        ...(talabatOrdersDebouncedFiltersQuery &&
          talabatOrdersDebouncedFiltersQuery),
        limit: talabatOrdersLimit,
        sortBy: talabatOrdersSortBy,
        sortingOrder: talabatOrdersSortingOrder,
        search: talabatOrdersDebouncedSearch,
      },
    };

    try {
      const data = await getExport({
        query: exportQueryParams[activeTab],
      }).unwrap();

      return data.items;
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const columns = useMemo(
    () => ({
      [TabKey.General]: GeneralTabColumns,
      [TabKey.Scheduled]: ScheduledTabColumns,
      [TabKey.Talabat]: TalabatTabColumns,
    }),
    [],
  );

  const locationOptions = useMemo(
    () =>
      locations?.map(({ name, id }) => ({
        label: name,
        type: id,
      })) || [],
    [locations],
  );

  const limit = useMemo(
    () => ({
      [TabKey.General]: ordersLimit,
      [TabKey.Scheduled]: scheduledOrdersLimit,
      [TabKey.Talabat]: talabatOrdersLimit,
    }),
    [ordersLimit, scheduledOrdersLimit, talabatOrdersLimit],
  );

  const page = useMemo(
    () => ({
      [TabKey.General]: ordersPage,
      [TabKey.Scheduled]: scheduledOrdersPage,
      [TabKey.Talabat]: talabatOrdersPage,
    }),
    [ordersPage, scheduledOrdersPage, talabatOrdersPage],
  );

  const setLimit = useMemo(
    () => ({
      [TabKey.General]: setOrdersLimit,
      [TabKey.Scheduled]: setScheduledOrdersLimit,
      [TabKey.Talabat]: setTalabatOrdersLimit,
    }),
    [setScheduledOrdersLimit, setOrdersLimit, setTalabatOrdersLimit],
  );

  const setPage = useMemo(
    () => ({
      [TabKey.General]: setOrdersPage,
      [TabKey.Scheduled]: setScheduledOrdersPage,
      [TabKey.Talabat]: setTalabatOrdersPage,
    }),
    [setOrdersPage, setScheduledOrdersPage, setTalabatOrdersPage],
  );

  const totalItems = useMemo(
    () => ({
      [TabKey.General]: ordersData?.totalCount,
      [TabKey.Scheduled]: scheduledOrdersData?.totalCount,
      [TabKey.Talabat]: talabatOrdersData?.totalCount,
    }),
    [ordersData, scheduledOrdersData, talabatOrdersData],
  );

  const createOrdersMenuItems = canOrdersCreate
    ? [
        {
          key: "1",
          label: "Customer manual order",
          onClick: createNewOrder,
        },
        {
          key: "2",
          label: "Talabat order",
          onClick: createNewTalabatOrder,
        },
      ]
    : [];

  const isExportLoading =
    isExportOrdersLoading || isExportScheduledLoading || isExportTalabatLoading;

  const exportFileNames = useMemo(
    () => ({
      [TabKey.General]: "orders",
      [TabKey.Scheduled]: "scheduled_orders",
      [TabKey.Talabat]: "talabat_orders",
    }),
    [],
  );

  const exportDateFormat =
    activeTab === TabKey.Talabat ? SHORT_TABLE_DATE : null;

  const dropDownMenuItems = [
    ...createOrdersMenuItems,
    {
      key: "3",
      label: "Export",
      disabled: !totalItems[activeTab],
      onClick: () =>
        downloadCSV({
          fileName: exportFileNames[activeTab],
          isDataLoading: false,
          columns: columns[activeTab],
          getExportData: getExportData,
          dateFormat: exportDateFormat,
        }),
    },
  ];

  const items = [
    {
      key: TabKey.General,
      label: TabKey.General,
      children: (
        <GeneralTab
          isLoading={isOrdersLoading}
          isDesktop={isDesktop}
          ordersData={ordersData}
          handleOrdersSort={handleOrdersSort}
        />
      ),
    },
    {
      key: TabKey.Scheduled,
      label: TabKey.Scheduled,
      children: (
        <ScheduledTab
          locationOptions={locationOptions}
          isLoading={isScheduledOrdersLoading}
          isFetching={isScheduledOrdersFetching}
          isDesktop={isDesktop}
          ordersData={scheduledOrdersData}
          handleOrdersSort={handleScheduledOrdersSort}
        />
      ),
    },
    {
      key: TabKey.Talabat,
      label: TabKey.Talabat,
      children: (
        <TalabatTab
          locationOptions={locationOptions}
          isLoading={isTalabatOrdersLoading}
          isFetching={isTalabatOrdersLoading}
          isDesktop={isDesktop}
          ordersData={talabatOrdersData}
          handleOrdersSort={handleTalabatOrdersSort}
        />
      ),
    },
  ];

  return (
    <PageWrapper $fullwidth $column>
      <FlexContainer
        $fullwidth
        $column
        $grow={1}
        $padding={isDesktop && "0 0 32px"}
      >
        <FlexContainer
          $padding="0 0 24px"
          $align="center"
          $justify="space-between"
        >
          <Typography.H1>Orders</Typography.H1>

          {isDesktop ? (
            <FlexContainer $gap={10}>
              <Export
                fileName={exportFileNames[activeTab]}
                isLoading={isExportLoading}
                isDataLoading={false}
                isDisabled={!totalItems[activeTab]}
                columns={columns[activeTab]}
                getExportData={getExportData}
                dateFormat={exportDateFormat}
              />

              {canOrdersCreate && (
                <DropDown trigger={["click"]} items={createOrdersMenuItems}>
                  <Button.Heading
                    icon={<PlusIcon fill={theme.color.white} />}
                    type="primary"
                  >
                    New Order
                  </Button.Heading>
                </DropDown>
              )}
            </FlexContainer>
          ) : (
            <DropDown trigger={["click"]} items={dropDownMenuItems}>
              <Button.SquaredIcon icon={<RightBurgerMenuIcon />} />
            </DropDown>
          )}
        </FlexContainer>

        <Tabs
          activeKey={activeTab}
          items={items}
          onChange={(activeKey: TabKey) => setActiveTab(activeKey)}
        />
      </FlexContainer>

      <Pagination
        onPage
        padding="12px 16px"
        showSizeChanger={!isDesktop}
        pageSizeOptions={pageSizeOptions}
        limit={limit[activeTab]}
        page={page[activeTab]}
        setLimit={setLimit[activeTab]}
        setPage={setPage[activeTab]}
        totalItems={totalItems[activeTab]}
      />
    </PageWrapper>
  );
};
